import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { SignupComponent } from './auth/register/signup.component';
import { ResetpwdComponent } from './auth/pwdreset/resetpwd.component';
import { NotfoundComponent } from './notfound.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor, ErrorInterceptor } from './services/interceptor';
import { ApiService } from './Services/api.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { MyDatePickerModule } from 'mydatepicker';
import { OtpComponent } from './auth/otp/otp.component';
import { NewpwdComponent } from './auth/newpwd/newpwd.component';
import { ExcelService } from './services/ReportService/excel.service';
import {DatePipe} from '@angular/common';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    ResetpwdComponent,
    NotfoundComponent,
    OtpComponent,
    NewpwdComponent,
    
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    MyDatePickerModule,
    FormsModule,
    ToastrModule.forRoot({
    }),
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ApiService,
    ExcelService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
