import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../authservice';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
                location.reload(true);
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }))

        // if (!(localStorage.getItem('token'))) {
        //     return next.handle(req.clone())
        //             err => {
        //                 if (err.status === 401) {
        //                     this.router.navigateByUrl('/login');
        //                 }
        //             };
        // }

        // if (localStorage.getItem('token')) {
        //     const clonedreq = req.clone({
        //         headers: req.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        //     });
        //     return next.handle(clonedreq)
        //             err => {
        //                 if (err.status === 401) {
        //                     this.router.navigateByUrl('/login');
        //                 }
        //             };
        // }
    }
}