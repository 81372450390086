import { Component, OnInit, TemplateRef } from '@angular/core';
import { AllServicesService } from '../../Services/all-services.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'csi-newpwd',
  templateUrl: './newpwd.component.html',
  styleUrls: ['./newpwd.component.css']
})
export class NewpwdComponent implements OnInit {

  NewPasswordForm: FormGroup;
  loading = false;
  ismodelOpen: boolean = false;
  submitted = false;
  token: string;
  constructor(private allservices: AllServicesService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute) {

      this.route.params.subscribe( params => { this.token = params["token"]}); 
     }

  ngOnInit() {

    var location = window.location.href;
    this.token= location.split("=")[1];
    this.NewPasswordForm1();
  }


  NewPasswordForm1() {
    this.NewPasswordForm = this.formBuilder.group({
      // Email: ['', Validators.required],
      Password: ['', Validators.required],
      ConPassword: ['', Validators.required],
    })
  }
  get f() { return this.NewPasswordForm.controls; }



  Submit() {
    this.spinner.show();
    this.submitted = true;
    if (this.NewPasswordForm.invalid) {
      this.spinner.hide();
      return false;
    }
    const data =
    {
     // "Email": this.NewPasswordForm.controls['Email'].value,
      'Password': this.NewPasswordForm.controls['Password'].value,
      'ConfirmPassword': this.NewPasswordForm.controls['ConPassword'].value,
      'token': this.token
    }
    
    if(data.Password == data.ConfirmPassword){
      this.allservices.ResetPassword(data).subscribe((result:RootObject) => {
        if(result.isSuccess){
      //  localStorage.setItem("token", result.accessToken);
        this.router.navigate(['login']);
        this.toastr.success("Password Changed Successfully.")
        // this.RegisterForm();
        this.spinner.hide();
        }
        else{
          
          this.toastr.warning("Password change unsuccessful.");
          this.submitted = false;
          this.NewPasswordForm.reset();
          this.spinner.hide();
        }
      },
        (err: HttpErrorResponse) => {
        });
    }
    else{
      this.toastr.warning("Password and confirm password does not Match.");
      this.spinner.hide();
    }
  }
}
export interface userInfo {
  Email: string;
  Password?: any;
  ConfirmPassword?: any;
  errorMessage  : any
}
export interface RootObject {
  userInfo: userInfo;
  accessToken: string;
  ExpiresIn: number;
  statusCode: number;
  isSuccess:boolean;
}


