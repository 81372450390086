import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AllServicesService } from '../../Services/all-services.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'csi-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  RegistartionForm: FormGroup;
  loading = false;
  ismodelOpen: boolean = false;
  submitted = false;
  constructor(private allservices: AllServicesService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.RegisterForm();
  }
  // ngAfterViewInit(){
  //   this.RegistartionForm.controls['Email'].setValue('');
  // }
   emailDomainValidator(control: FormControl) { (1)
    let email = control.value; (2)
    if (email && email.indexOf("@") != -1) { (3)
      let [_, domain] = email.split("@"); (4)
      if (domain == "gmail.com" ||domain == "smartdatainc.net" || domain == "capplesales.net" || domain =="cappelsales.com") { (5)
         return null;
      }
      else
      {
        return {
          emailDomain: true
        }
      }
    }
    return null; (6)
  }

  RegisterForm() {
    this.RegistartionForm = this.formBuilder.group({
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      Email: ['', [Validators.required,this.emailDomainValidator]],
      Password: ['', Validators.required],
      ConPassword: ['', Validators.required],
    })
  }
  get f() { return this.RegistartionForm.controls; }



  Register() {
    var NAme =this.RegistartionForm.controls['FirstName'].value;
    NAme = NAme.replace(/\s/g, "");
    var LastNAme =this.RegistartionForm.controls['LastName'].value;
    LastNAme = LastNAme.replace(/\s/g, "");

    if(!NAme)
    this.RegistartionForm.controls['FirstName'].setErrors({'required': true});
    if(!LastNAme)
    this.RegistartionForm.controls['LastName'].setErrors({'required': true});
    
    this.spinner.show();
    this.submitted = true;
    if (this.RegistartionForm.invalid) {
      this.spinner.hide();
      return false;
    }
    const data =
    {
      'FirstName': this.RegistartionForm.controls['FirstName'].value,
      'LastName': this.RegistartionForm.controls['LastName'].value,
      'Email': this.RegistartionForm.controls['Email'].value,
      'Password': this.RegistartionForm.controls['Password'].value,
      'ConPassword': this.RegistartionForm.controls['ConPassword'].value,
    }
    var name = data.FirstName + " " + data.LastName;
    localStorage.removeItem('userName')
    localStorage.setItem('userName',name)
    if(data.Password == data.ConPassword){
      this.allservices.Register(data).subscribe((result:RootObject) => {
        if(result.statusCode == 200){
        // localStorage.setItem("token", result.accessToken);
        this.router.navigate(['otp']);
        this.toastr.success("Registration Completed Successfully.")
        // this.RegisterForm();
        this.spinner.hide();
        }
        else{
          var message = result.userInfo.errorMessage[0];
          this.toastr.warning(message);
          this.submitted = false;
         // this.RegistartionForm.reset();
          this.spinner.hide();
        }
      },
        (err: HttpErrorResponse) => {
        });
    }
    else{
      this.toastr.warning("Password And Confirm Password Does not Match.");
      this.spinner.hide();
    }
  }
}
export interface userInfo {
  Id: string;
  firstName: string;
  lastName: string;
  Email: string;
  Password?: any;
  ConfirmPassword?: any;
  errorMessage  : any
}
export interface RootObject {
  userInfo: userInfo;
  accessToken: string;
  ExpiresIn: number;
  statusCode: number;
}
