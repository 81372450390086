import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AllServicesService } from '../../Services/all-services.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'csi-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  ischecked :boolean = false;
  error = '';
  // public loading = false;
  constructor(
    private formBuilder: FormBuilder,
    // private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private allservices: AllServicesService,
  ) {}

  ngOnInit() {
    this.spinner.hide();
    let lastEmailUsed = sessionStorage.getItem('lastEmailUsed') ? sessionStorage.getItem('lastEmailUsed') : '';
    this.loginForm = this.formBuilder.group({
      username: [lastEmailUsed, Validators.required],
      password: ['', Validators.required]
    });
    const currentUser =localStorage.getItem("token");
    if(currentUser){
      //  this.ischecked = true;
      this.router.navigate(['app/dashboard']);
    }
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return false;
    }
    this.spinner.show();
    const data =
    {
      'Email': this.loginForm.value.username,
      'password': this.loginForm.value.password,
      'Image':this.loginForm.value.image,
    }
    this.allservices.login(data).subscribe((result:RootObject) => {
      if (result.statusCode == 200) 
      {
        sessionStorage.setItem('lastEmailUsed', this.loginForm.value.username);
        localStorage.removeItem('Role');
        localStorage.removeItem('AllRoleList');
        localStorage.setItem('AllRoleList',result.userRoles);
        localStorage.setItem('Role',result.userRoles[0]);
      //  this.allservices.saveUserProfileImage(result.userInfo.image);
        this.allservices.saveUserProfile(result.userInfo.firstName+" "+result.userInfo.lastName);
        localStorage.setItem('loginId',result.userInfo.id);
        localStorage.setItem("token", result.accessToken);
        if(result){
          var Name = result.userInfo.firstName + " " + result.userInfo.lastName;
          localStorage.removeItem('userName')
          localStorage.setItem('userName',Name);
          localStorage.removeItem('image');
          localStorage.setItem('image',result.userInfo.image)
          this.router.navigate(['app/dashboard']);
        }
        // this.spinner.hide();
        setTimeout(()=>{    //<<<---    using ()=> syntax
        this.toastr.success("Welcome" + " " +result.userInfo.firstName + " " + result.userInfo.lastName)
        }, 1000);
      
      }
      else {
        var message = result.userInfo.errorMessage[0];
        this.toastr.warning(message);
        this.spinner.hide();
      }
    },
      (err: HttpErrorResponse) => {
      });
  }
}
export interface userInfo {
  id: string;
  firstName: string;
  lastName: string;
  Email: string;
  Password?: any;
  ConfirmPassword?: any;
  errorMessage  : any;
  image : any
}

export interface RootObject {
  userInfo: userInfo;
  accessToken: string;
  ExpiresIn: number;
  statusCode: number;
  userRoles:any
}
