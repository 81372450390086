import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'csi-notfound',
  template: `
    <p>
    <img style="min-width: 1092px; margin-left: 159px;cursor: pointer;" (click)="changeUrl()" class="img-fluid able-logo" src="../../../../../assets/images/free-html-404-error-page-template.jpg"
    alt="Theme-logo">
    </p>
  `,
  styles: []
})
export class NotfoundComponent implements OnInit {
  constructor( private router: Router,) {}

  ngOnInit() {}
  changeUrl(){
    const currentUser =localStorage.getItem("token");
    if(currentUser){
      this.router.navigate(['app/dashboard']);
    }
    else{
      this.router.navigate(['login']);
    }
  }
}
