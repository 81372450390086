import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authservice';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        //let currentUser = this.authenticationService.currentUserValue;
       let token = localStorage.getItem("token");
        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                },
                headers: new HttpHeaders({
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                  })
            });
            // request.headers.set('Authorization', 'Bearer ' + token);
        }
        
        return next.handle(request);
    }
}