import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router,ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AllServicesService } from 'src/app/Services/all-services.service';

@Component({
  selector: 'csi-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit {
  otpForm: FormGroup;
  submitted = false;
  userid: string;
;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private allservices: AllServicesService
  ) { 

    this.route.params.subscribe( params => { this.userid = params["userid"]}); 
  }
  HomePage(){
    this.router.navigate(['login']);
  }
  ngOnInit() {
    var location = window.location.href;
    this.userid= location.split("=")[1];
    
    this.otpForm = this.formBuilder.group({
      otp: ['', Validators.required]
    });
  }

  get f() {
    return this.otpForm.controls;
  }

  keypressDigit(event) {
    if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode == 46)) {
        return true;
    }
    else {
        event.preventDefault();
    }
}

  onSubmit() {
    this.spinner.show();
    this.submitted = true;
    if (this.otpForm.invalid) {
      return false;
    }
    const data =
    {
      'otp': this.otpForm.value.otp,
      'userId':this.userid
    }
    this.allservices.otpVerification(data).subscribe((result:any) => {
      if (result.isSuccess) 
      {
        this.toastr.success(result.message);
          this.router.navigate(['login']);
          this.spinner.hide();
      }
      else {
        this.toastr.warning(result.message);
        this.spinner.hide();
      }
    },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
      });
  }

}
