import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { NotfoundComponent } from './notfound.component';
import { SignupComponent } from './auth/register/signup.component';
import { ResetpwdComponent } from './auth/pwdreset/resetpwd.component';
import { AuthGuard } from './services/guards';
import { OtpComponent } from './auth/otp/otp.component';
import { NewpwdComponent } from './auth/newpwd/newpwd.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: SignupComponent
  },
  {
    path: 'otp',
    component: OtpComponent
  },
  {
    path: 'forgot-password',
    component: ResetpwdComponent
  },
  {
    path: 'reset-password',
    component: NewpwdComponent
  },
  {
    path: 'app',
    loadChildren: './modules/protected.module#ProtectedModule',
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: NotfoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
