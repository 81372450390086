import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class ApiService {

    getUrl(url: string): string {
  
        return environment.BASE_API_URL + url;
    }
    
    param: string;

    constructor(private http: HttpClient, ) { }

    postData<T>(url: string, data: {}, reqAPIParams: string | null) {  


        const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });

        if (reqAPIParams != null) {

            url = url + reqAPIParams;
        }

        return this.http.post(this.getUrl(url), data, { headers: reqHeader }).pipe();
    }
    getData<T>(url: string, reqAPIParams: string | null) {
        const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });

        if (reqAPIParams != null) {
            url = url + reqAPIParams;
        }

        return this.http.get(this.getUrl(url), { headers: reqHeader });
    }
    postData1<T>(url: string, data: {}, reqAPIParams: string | null) {  


        const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });

        if (reqAPIParams != null) {

            url = url + reqAPIParams;
        }

        return this.http.post('https://localhost:44362/'+url, data, { headers: reqHeader }).pipe();
    }
    
    getData1<T>(url: string, reqAPIParams: string | null) {
        const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });

        if (reqAPIParams != null) {
            url = url + reqAPIParams;
        }

        return this.http.get("https://localhost:44362/" + url, { headers: reqHeader });
    }

    getEquipmentReport(url: string): Observable<HttpResponse<Blob>> {
        
        return this.http.get(this.getUrl(url), { responseType: 'blob', observe: 'response' });
      }
}

