import { Component } from '@angular/core';
import { OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
//import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'csi-root',
  template: `
  <ngx-spinner bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-pulse">
   <p style="font-size: 20px; color: white">Loading...</p></ngx-spinner>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    
    // const currentUser =localStorage.getItem("token");
    // if(currentUser){
    //   this.router.navigate(['app/dashboard']);
    // }

    try {
      //   let deviceInfo = this.deviceService.getDeviceInfo();
      //   console.log(deviceInfo);
      //   if (deviceInfo) {
      localStorage.setItem('browser', this.findBrowser())
      console.log(localStorage.getItem('browser'));
      //     localStorage.setItem('device', JSON.stringify(deviceInfo))
      //   }
    } catch (err) { }
  }
  title = 'CSI-Frontend';

  findBrowser() {
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      return 'Firefox';
    }
    else {
      return 'unknown';
    }
  }
  
}
